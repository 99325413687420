import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { Section, Card } from './style'

const EscolhaOGiftCard = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 text-white mb-5 fw-600 text-center'>
              Escolha o Gift Card e deixe o cashback por nossa conta:
            </h2>
            <div className='row'>
              <div className='col-12 col-md-4'>
                <Card
                  className='first-card h-100'
                  href='https://inter-co.onelink.me/Qyu7/g0jn6qia'
                  target='_blank'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_6',
                      element_action: 'click button',
                      element_name: 'Economia no dia a dia',
                      redirect_url: 'https://inter-co.onelink.me/Qyu7/g0jn6qia',
                     })
                  }}
                >
                  <div className='row d-flex align-items-center justify-content-md-between h-100'>
                    <div className='col-6 col-md-12'>
                      <h3 className='fs-16 lh-20 fs-xl-24 lh-xl-30 text-white mb-3'>Economia no dia a dia</h3>
                      <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-white mb-0'>Compras diárias, delivery, Uber e até gás.</p>
                    </div>
                    <div className='col-6 col-md-12'>
                      <img src=' https://central-imagens.bancointer.com.br/images-without-small-versions/economia-dia-dia/image.webp' alt='ilustração de carro laranja ao lado de um botijão de gás' />
                    </div>
                  </div>
                </Card>
              </div>
              <div className='col-12 col-md-8'>
                <Card
                  className='mb-4'
                  href='https://inter-co.onelink.me/Qyu7/g0jn6qia'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_6',
                      element_action: 'click button',
                      element_name: 'Gift Cards de jogos',
                      redirect_url: 'https://inter-co.onelink.me/Qyu7/g0jn6qia',
                     })
                  }}
                  target='_blank'
                >
                  <div className='row d-flex align-items-center justify-content-between'>
                    <div className='col-6 col-md-7'>
                      <h3 className='fs-16 lh-20 fs-xl-24 lh-xl-30 text-white mb-3'>Gift Cards de jogos</h3>
                      <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-white mb-0'>Recarga de jogos e tudo pra sua gameplay.</p>
                    </div>
                    <div className='col-6 col-md-5'>
                      <img src='https://central-imagens.bancointer.com.br/images-without-small-versions/Games/image.webp' alt='ilustração de um console' />
                    </div>
                  </div>
                </Card>
                <Card
                  href='https://inter-co.onelink.me/Qyu7/g0jn6qia'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_6',
                      element_action: 'click button',
                      element_name: 'Gift Cards de Streamings',
                      redirect_url: 'https://inter-co.onelink.me/Qyu7/g0jn6qia',
                     })
                  }}
                  target='_blank'
                >
                  <div className='row d-flex align-items-center justify-content-between'>
                    <div className='col-6 col-md-7'>
                      <h3 className='fs-16 lh-20 fs-xl-24 lh-xl-30 text-white mb-3'>Gift Cards de Streamings</h3>
                      <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-white mb-0'>Renove a assinatura e amplie as opções de séries e filmes.</p>
                    </div>
                    <div className='col-6 col-md-5'>
                      <img src='https://central-imagens.bancointer.com.br/images-without-small-versions/Gift-cards-streamings/image.webp' alt='Ilustração de tela com ícone de play ao centro e balde de pipoca ao lado' />
                    </div>
                  </div>
                </Card>
              </div>
              <div className='col-12 col-md-6 mt-4'>
                <Card
                  href='https://inter-co.onelink.me/Qyu7/g0jn6qia'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_6',
                      element_action: 'click button',
                      element_name: 'Gift Cards de alimentação',
                      redirect_url: 'https://inter-co.onelink.me/Qyu7/g0jn6qia',
                     })
                  }}
                  target='_blank'
                >
                  <div className='row d-flex align-items-center justify-content-between'>
                    <div className='col-6 col-md-6'>
                      <h3 className='fs-16 lh-20 fs-xl-24 lh-xl-30 text-white mb-3'>Gift Cards de alimentação</h3>
                      <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-white mb-0'>Restaurantes, hamburguerias e até bebida gelada.</p>
                    </div>
                    <div className='col-6 col-md-6'>
                      <img src='https://central-imagens.bancointer.com.br/images-without-small-versions/alimentacao/image.webp' alt='ilustração de batata frita ao lado de um hambúrguer' />
                    </div>
                  </div>
                </Card>
              </div>
              <div className='col-12 col-md-6 mt-4'>
                <Card
                  className='vale-presente'
                  href='https://inter-co.onelink.me/Qyu7/g0jn6qia'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_6',
                      element_action: 'click button',
                      element_name: 'Vale presente',
                      redirect_url: 'https://inter-co.onelink.me/Qyu7/g0jn6qia',
                     })
                  }}
                >
                  <div className='row d-flex align-items-center justify-content-between'>
                    <div className='col-6 col-md-7 '>
                      <h3 className='fs-16 lh-20 fs-xl-24 lh-xl-30 text-white mb-3'>Vale presente</h3>
                      <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-white mb-0'>Mais de 100 lojas para presentear, sem erro.</p>
                    </div>
                    <div className='col-6 col-md-5'>
                      <img src='https://central-imagens.bancointer.com.br/images-without-small-versions/vale-presente/image.webp' alt='ilustração de gift card físico laranja' />
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default EscolhaOGiftCard
