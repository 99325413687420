import React from 'react'
import Typed from 'react-typed'

import { Section, Input } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const Hero = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5 mt-0 d-md-flex align-items-center'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h1 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50  fw-600 text-grayscale--500'>
              <span className='d-block'>Gift Card Inter:</span> muito mais que
              <Typed
                strings={[
                    'vale-compras',
                    'economia',
                    'desconto',
                    'presente',
                    'voucher',
                    'cashback',
                    'cupom',
                    'pagamento digital',
                ]}
                typeSpeed={40}
                backSpeed={50}
                attr='placeholder'
                loop
              >
                <Input readOnly className='px-auto' />
              </Typed>
            </h1>
            <p className='fs-14 fs-md-16 fs-xl-18 lh-17 lh-md-19 lh-xl-22 text-grayscale--400'>
              A forma simples e rápida para economizar em todas as suas compras, seja no Brasil ou no exterior.
            </p>
            <div className='col-12 pt-4 px-0'>
              <a
                href='https://inter-co.onelink.me/Qyu7/g0jn6qia'
                target='_blank'
                rel='noreferrer'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Gift Cards Inter Fácil de usar, presentear e economizar',
                    element_action: 'click button',
                    element_name: 'Quero economizar',
                    redirect_url: 'https://inter-co.onelink.me/Qyu7/g0jn6qia',
                  })
                }}
                className='btn btn-orange--extra rounded-2 fs-14 fw-600 text-none'
              >
                Ver Gift Cards disponíveis
              </a>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
