import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import SimuladorCashback from './../../simuladorCashback/_index'

import { Section } from './style'

const FazerAsContas = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5 bg-grayscale--100'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center'>
          <div className='col-12 col-md-6 px-0'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 mb-md-4 fw-600 text-md-center text-lg-left text-grayscale--500'>
              Comprar Gift Card = economia na hora
            </h2>
            <p className='fs-14 fs-md-16 fs-xl-18 lh-16 lh-md-19 lh-xl-22 text-md-center text-lg-left mt-4 mt-md-0 mt-lg-4 fw-400 text-grayscale--400'>
              Use os <strong>Gift Cards Inter</strong> e receba cashback exclusivo. Sem enrolação, garanta o seu <strong>vale presente</strong> e receba uma parte do valor pago de volta, direto na sua conta.
            </p>
            <div className='col-12 pt-4 px-0 text-md-center text-lg-left'>
              <a
                href='https://inter-co.onelink.me/Qyu7/g0jn6qia'
                target='_blank'
                rel='noreferrer'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    section_name: 'É só fazer as contas pra ir de Gift Card',
                    element_action: 'click button',
                    element_name: 'Quero economizar',
                    redirect_url: 'https://inter-co.onelink.me/Qyu7/g0jn6qia',
                  })
                }}
                className='btn btn-orange--extra rounded-2 fs-14 fw-600 text-none'
              >
                Quero economizar
              </a>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <SimuladorCashback />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default FazerAsContas
